import { helpScoutBeaconId, isDemoSite } from "consts/client";
import { AppWrapper } from "context/AppContext";
import useCurrentUser from "hooks/useCurrentUser";
import { NextQueryParamProvider } from "next-query-params";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import NProgress from "nprogress";
import { useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { ReactQueryDevtools } from "react-query-devtools";
import "styles/index.css";

const PembeeApp = ({ Component, pageProps }: AppProps) => {
  const { data: currentUser } = useCurrentUser();

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      NProgress.start();
    };

    const handleRouteChangeComplete = () => {
      // HelpScout Beacon page change
      if (
        typeof window !== "undefined" &&
        window["Beacon"] &&
        window["Beacon"]("info")
      ) {
        window["Beacon"]("event", {
          type: "page-viewed",
          url: document.location.href,
          title: document.title
        });
        window["Beacon"]("suggest");
      }

      // NProgress
      NProgress.done();
    };

    router.events.on("routeChangeStart", handleRouteChange);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeError", () => NProgress.done());

    // HelpScout Beacon init
    if (
      typeof window !== "undefined" &&
      window["Beacon"] &&
      !window["Beacon"]("info") &&
      currentUser?.accessLevel >= 10
    ) {
      window["Beacon"]("init", helpScoutBeaconId);
      if (!isDemoSite) {
        window["Beacon"]("identify", {
          name: currentUser.fullName,
          email: currentUser.email,
          signature: currentUser.signature,
          "primary-admin": currentUser.isPrimaryAdmin ? "Yes" : "No"
        });
      }
    }

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [currentUser, router.events]);

  return (
    <>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      <NextQueryParamProvider>
        <AppWrapper>
          <Component {...pageProps} />
        </AppWrapper>
      </NextQueryParamProvider>
    </>
  );
};

export default PembeeApp;
